<template>
	<div :class="styleClass" @click="clickFunction">
		<h3 class="gridheading">{{griditem.heading}}</h3>
		<h5 v-if="isTimeStampPresent">Updated: {{updated}}</h5>
		<p v-if="isContentPreviewPresent">{{griditem.contentPreview}}</p>
		<p class="hovertext">
			<section class="visible">    </section>
			<section class="hidden">View Details</section>
		</p>
	</div>
</template>

<script>
import moment from 'moment-timezone'
moment.tz.setDefault('UTC');
export default {
	props:['griditem', 'klass'],
	data(){
		return{
			updated: null
		}
	},
	methods:{
		clickFunction(){
			this.$emit('gridItemClicked', this.griditem);
		}
	},
	computed:{
		styleClass(){
			return 'griditem' + ' ' + this.klass;
		},
		isHeading(){
			if (this.griditem && this.griditem.heading)
				return true;
			return false;	
		},
		isTimeStampPresent(){
			if (this.griditem && this.griditem.timestamp){
				return true;
			}
			return false;	
		},
		isContentPreviewPresent(){
			if (this.griditem && this.griditem.contentPreview)
				return true;
			return false;	
		}

	},
	mounted(){
		if (this.griditem && this.griditem.timestamp){
			this.updated = moment(this.griditem.timestamp).format('DD-MM-YYYY');
		}
	}
}
</script>

<style scoped>
	.gridheading{
		background-image: linear-gradient(0deg,rgba(0,0,0,.1),transparent 76%),linear-gradient(
	90deg,#3B4755 0,#3B4755 74px, #3B4755 0, #3B4755);
		/* opacity: 60%; */
		margin: 0;
		padding: 2px;
		/* margin: 3px; */
		border-radius: 3px;
	}
	.griditem{
		white-space: initial;
		/* background: lightseagreen;
		border: 1px solid gray; */
		border-radius: 5px;
		background: 323335;
	}

	.griditem:hover {
		cursor: pointer;
		/* border: #725162 1px solid; */
		/* background: #725162; */
	}

	.griditem p, h3{
		/* overflow: auto; */
		/* word-wrap: break-word; */
		/* overflow-wrap: break-word; */
		/* background: white; */
		margin-left: 2px;
		margin-right: 2px;
		/* width: 100px; */
		/* white-space: initial; */
	}

	.griditem section{
		display: block;
		opacity: 0%;
	}
	@media (hover: hover) {
		.griditem span {
			/* transform: translateY(10px); */
			transition: 0.2s;
		}
		.griditem:hover section {
			transform: translateY(0);
			transition-delay: 0.0s;
			/* background: rgba(0, 0, 0, 0.1); */
			background: var(--grid-hover-color);
			opacity: 100%;
		}
	}
	/* Extra-wide grid-posts */
	/* .griditem:nth-child(31n + 1) {
		grid-column: 1 / -1;
	}
	.griditem:nth-child(16n + 2) {
		grid-column: -3 / -1;
	}
	.griditem:nth-child(16n + 10) {
		grid-column: 1 / -2;
	} */
</style>